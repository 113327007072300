<template>
  <div class="home">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="header_title">
            <div class="deviceBack">
              <i class="el-icon-arrow-left" @click="back()"></i>
            </div>
            <span>地址信息详情</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="loginMain">
          <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            label-width="92px"
            status-icon
          >
            <el-form-item prop="name" label="收件人：">
              <el-input v-model="dataForm.name" placeholder="请输入收件人" />
            </el-form-item>
            <el-form-item prop="mobile" label="电话号码：">
              <el-input
                v-model="dataForm.mobile"
                placeholder="请输入电话号码"
              />
            </el-form-item>
            <el-form-item prop="address" label="收件地址：">
              <el-input
                v-model="dataForm.address"
                type="textarea"
                rows="8"
                placeholder="请输入收件地址"
              />
            </el-form-item>
            <el-form-item prop="isNess" label="默认地址：">
              <el-checkbox v-model="dataForm.isNess">是</el-checkbox>
            </el-form-item>
            <el-form-item label-width="0px">
              <el-button
                class="login-btn-submit"
                type="primary"
                @click="dataFormSubmit()"
                :loading="isLoading"
              >
                确定
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      dataForm: {
        id: 1,
        name: "某系人",
        mobile: "13679830253",
        address: "青田区选择人",
        isNess: 1
      },
      dataRule: {
        name: [{ required: true, message: '请填写收件人', trigger: 'blur' }],
        mobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }],
        address: [{ required: true, message: '请填写收件地址', trigger: 'blur' }],
      }
    }
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    getDataList() {
      let id = parseInt(this.$route.query.id);
      this.dataForm.id = id;
      this.$http.httpGet(`/cms/appAnnouncement/info/${id}`).then( data => {
        if (data && data.code === 0) {
          this.dataForm = {...data.appAnnouncement}
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.isLoading = true;
          this.isLoading = false;
          // this.$http.httpPost(`/mobile/login?mobile=${this.dataForm.userName}&password=${this.dataForm.password}`).then( data => {
          //   if (data && data.code === 0) {
          //     // this.$cookies.config('7d'); //设定过期时间为7天
          //     this.$cookies.set("shopToken", data.token, 60 * 60 * 24 * 7);
          //     this.isLoading = false;
          //     sessionStorage.setItem("store", JSON.stringify(this.$store.state))
          //     this.$router.replace({name: "Home"})
          //   } else {
          //     this.dataForm.password = null;
          //     this.$message.error(data.msg);
          //     this.isLoading = false;
          //   }
          // }).catch(() => {
          //   this.isLoading = false;
          // });
        }
      });
    },
    // 返回上一页
    back() {
      this.$router.replace({name: "address"});
    },
  },
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  .el-container {
    height: 100%;
    .el-main{
      padding: 0 !important;
      .loginMain {
        position: relative;
        margin: 0 auto;
        .el-form {
          position: relative;
          padding: 10px;
          .el-form-item {
            margin: 0 0 15px;
            .el-form-item__label {
              font-size: 12px;
              line-height: 34px;
            }
            .el-form-item__content {
              font-size: 12px;
              line-height: 34px;
              .el-input {
                font-size: 12px;
                .el-input__inner {
                  line-height: 34px;
                  height: 34px;
                }
              }
              .el-form-item__error {
                top: 100%;
              }
              .login-btn-submit {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
